@tailwind base;
@tailwind components;
@tailwind utilities;

/**************************/
/* CSS Animations Classes */
/**************************/

/* 
The following class-endings: *-enter, *-enter-active, *-exit, *-exit-active,
have very specific meanings to <CSSTransition />
(see more: https://reactcommunity.org/react-transition-group/css-transition)
*/

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-out;
}

/**************************/
/* Generic Styles Classes */
/**************************/

html,
body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

/* .h-screen {
  height: calc(var(--vh) * 100) !important;
} */

@media (max-width: 640px) {
  html {
    height: 100%;
  }
}

.splide__list {
  position: relative;
}

.splide__track,
.splide__slide {
  height: 100%;
}

.hide-scrollbar::-webkit-scrollbar{
  display: none;
}

.hide-scrollbar {
  scrollbar-width: none;
}

#kontakt p,
#imprint p,
#about p {
  margin-bottom: 20px;
}

.h-content.is-scrollable:before {
    content: "";
    z-index: 10;
    position: absolute;
    top: -10px;
    right: 0;
    height: 15px;
    left: 0;
    background: linear-gradient(to top left, #000000 100%, #2a2a2a 0% );
    filter: blur(25px);
    opacity: 0.7;
    transition: opacity 0.3s;
    border-radius: inherit;
}

.h-content.is-scrollable:after {
    content: "";
    z-index: 10;
    position: absolute;
    bottom: -10px;
    right: 0;
    height: 15px;
    left: 0;
    background: linear-gradient(to top left, #000000 100%, #2a2a2a 0% );
    filter: blur(25px);
    opacity: 0.7;
    transition: opacity 0.3s;
    border-radius: inherit;
}